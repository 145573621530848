import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Legend, PieChart, Pie, Cell, BarChart, Bar } from 'recharts';
import './FloorPopup.css';
import Papa from 'papaparse';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const controlLimits = {
  danger: 80,
  warning: 60,
  caution: 40,
  attention: 20,
  lower: 0,
};

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#0088FE', '#00C49F', '#FFBB28'];

const componentNames = {
  FP: '급수펌프',
  MKD: '발전기',
  MAK: '기어박스',
  MAA: '고압터빈',
  MAV: '윤활 오일',
  MAB: '중압터빈',
  IOT: 'IoT Data'
};

const componentColors = {
  FP: '#8884d8',
  MKD: '#82ca9d',
  MAK: '#ffc658',
  MAA: '#ff8042',
  MAV: '#0088FE',
  MAB: '#00C49F',
  IOT: '#FFBB28'
};

const FloorPopup = ({ floorNumber, onClose }) => {
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [combinedT2Data, setCombinedT2Data] = useState([]);
  const [rbcData, setRbcData] = useState([]);
  const [rbcEachData, setRbcEachData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [randomStartIndex, setRandomStartIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedIndex, setPausedIndex] = useState(null);

  // Function to generate random index
  const generateRandomIndex = (dataLength) => {
    return Math.floor(Math.random() * dataLength);
  };

  useEffect(() => {
    Promise.all([
      new Promise(resolve => {
        Papa.parse('/csvs/T2_df.csv', {
          download: true,
          header: true,
          complete: (result) => resolve(result.data),
        });
      }),
      Promise.all(['MKD', 'MAV', 'MAK', 'MAB', 'MAA', 'IOT', 'FP'].map(file => 
        new Promise((resolve) => {
          Papa.parse(`/csvs/T2_df_${file}.csv`, {
            download: true,
            header: true,
            complete: (result) => resolve({ file, data: result.data }),
          });
        })
      )),
      new Promise(resolve => {
        Papa.parse('/csvs/rbc_df.csv', {
          download: true,
          header: true,
          complete: (result) => resolve(result.data),
        });
      }),
      new Promise(resolve => {
        Papa.parse('/csvs/RBC_df_each.csv', {
          download: true,
          header: true,
          complete: (result) => resolve(result.data),
        });
      })
    ]).then(([timeSeriesData, combinedT2Results, rbcData, rbcEachData]) => {
      setTimeSeriesData(timeSeriesData);
      
      const combinedData = combinedT2Results[0].data.map((row, i) => {
        const combined = { datetime: row.datetime };
        combinedT2Results.forEach(({ file, data }) => {
          if (data[i] && data[i].T2 !== undefined) {
            combined[file] = parseFloat(data[i].T2);
          }
        });
        return combined;
      });
      setCombinedT2Data(combinedData);
      
      setRbcData(rbcData);
      setRbcEachData(rbcEachData);

      // Set random start index based on the length of the data
      const minLength = Math.min(timeSeriesData.length, combinedData.length, rbcData.length);
      const randomIndex = generateRandomIndex(minLength);
      setRandomStartIndex(randomIndex);
      setCurrentIndex(randomIndex);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        const maxLength = Math.min(timeSeriesData.length, combinedT2Data.length, rbcData.length);
        return nextIndex >= maxLength ? randomStartIndex : nextIndex;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [timeSeriesData.length, combinedT2Data.length, rbcData.length, randomStartIndex]);

  const handlePause = () => {
    setIsPaused(true);
    setPausedIndex(currentIndex);
  };

  const handleResume = () => {
    setIsPaused(false);
    setPausedIndex(null);
  };

  const displayIndex = isPaused ? pausedIndex : currentIndex;

  const displayTimeSeriesData = useMemo(() => {
    console.log('Time Series Data - Start Index:', displayIndex);
    return timeSeriesData.slice(displayIndex, displayIndex + 10).map((row, index) => {
      console.log(`Time Series Data - Row ${displayIndex + index}:`, row);
      return {
        name: row.datetime,
        value: parseFloat(row.T2),
      };
    });
  }, [timeSeriesData, displayIndex]);

  const displayCombinedT2Data = useMemo(() => {
    console.log('Combined T2 Data - Start Index:', displayIndex);
    return combinedT2Data.slice(displayIndex, displayIndex + 10).map((row, index) => {
      // console.log(`Combined T2 Data - Row ${displayIndex + index}:`, row);
      return row;
    });
  }, [combinedT2Data, displayIndex]);

  const displayRbcData = useMemo(() => {
    console.log('RBC Data - Start Index:', displayIndex);
    return rbcData.slice(displayIndex, displayIndex + 10).map((row, index) => {
      // console.log(`RBC Data - Row ${displayIndex + index}:`, row);
      const total = Object.entries(row)
        .filter(([key]) => key !== 'datetime')
        .reduce((sum, [, value]) => sum + parseFloat(value), 0);

      const percentages = {};
      Object.entries(row).forEach(([key, value]) => {
        if (key !== 'datetime') {
          const componentKey = key.replace('RBC_', '');
          percentages[componentNames[componentKey] || componentKey] = (parseFloat(value) / total) * 100;
        }
      });

      return { ...percentages, datetime: row.datetime };
    });
  }, [rbcData, displayIndex]);

  const breakdownData = useMemo(() => {
    if (displayRbcData.length === 0) return [];
    
    const lastUpdatedData = displayRbcData[displayRbcData.length - 1];
    //console.log('Breakdown Data - Using last updated RBC data:', lastUpdatedData);
    
    return Object.entries(lastUpdatedData)
      .filter(([key]) => key !== 'datetime')
      .map(([key, value]) => ({
        name: key,
        value: value
      }));
  }, [displayRbcData]);

  const displayRbcEachData = useMemo(() => {
    if (rbcEachData.length === 0 || displayIndex >= rbcEachData.length) return [];

    const currentData = rbcEachData[displayIndex];
    const sortedComponents = Object.entries(currentData)
      .filter(([key]) => key !== 'datetime')
      .sort(([, a], [, b]) => parseFloat(b) - parseFloat(a))
      .slice(0, 10)
      .map(([key, value]) => {
        const componentKey = Object.keys(componentNames).find(comp => key.includes(comp));
        const name = componentKey ? `${key} (${componentNames[componentKey]})` : key;
        const color = componentKey ? componentColors[componentKey] : '#8884d8';
        return {
          name,
          value: parseFloat(value),
          color
        };
      });

    return sortedComponents;
  }, [rbcEachData, displayIndex]);

  // Add this new memoized value to calculate X-axis domain
  const xAxisDomain = useMemo(() => {
    if (displayRbcEachData.length === 0) return [0, 100];
    const maxValue = Math.max(...displayRbcEachData.map(item => item.value));
    return [0, Math.ceil(maxValue / 10) * 10]; // Round up to the nearest 10
  }, [displayRbcEachData]);

  useEffect(() => {
    // console.log('Current Index:', currentIndex);
    // console.log('Random Start Index:', randomStartIndex);
  }, [currentIndex, randomStartIndex]);

  const handleChartClick = (data) => {
    if (data && data.activeLabel) {
      const newIndex = timeSeriesData.findIndex(row => row.datetime === data.activeLabel);
      if (newIndex !== -1) {
        setCurrentIndex(newIndex);
      }
    }
  };

  return (
    <div className="floor-popup-overlay">
      <div className="floor-popup">
        <div className="popup-header">
          <h2>{floorNumber === 1 || floorNumber === 2 ? `${floorNumber} 호기 화재 개별 지수` : '기타 공간 화재 개별 지수'}</h2>
          <div className="header-buttons">
            <button className="toggle-button" onClick={isPaused ? handleResume : handlePause}>
              {isPaused ? (
                <>
                  <PlayArrowIcon /> 실시간
                </>
              ) : (
                <>
                  <PauseIcon /> 일시 정지
                </>
              )}
            </button>
            <button className="toggle-button close-button" onClick={onClose}>
              닫기
            </button>
          </div>
        </div>
        <div className="chart-grid">
          <div className="chart-container">
            <h3>{floorNumber === 1 || floorNumber === 2 ? '화재 개별 지수' : '기타 공간 화재 개별 지수'}</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={displayTimeSeriesData} onClick={handleChartClick}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <ReferenceLine y={controlLimits.danger} label="심각" stroke="red" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.warning} label="경계" stroke="orange" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.caution} label="주의" stroke="yellow" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.attention} label="관심" stroke="green" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.lower} label="안정" stroke="blue" strokeDasharray="3 3" />
                <Tooltip />
                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-container">
            <h3>시간별 화재 개별 지수 영향요인</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={displayRbcData} onClick={handleChartClick}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="datetime" />
                <YAxis domain={[0, 100]} />
                <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                <Legend />
                {Object.keys(rbcData[0] || {}).filter(key => key !== 'datetime').map((key) => {
                  const componentKey = key.replace('RBC_', '');
                  return (
                    <Line 
                      key={componentKey} 
                      type="monotone" 
                      dataKey={componentNames[componentKey] || componentKey} 
                      stroke={componentColors[componentKey] || '#000000'} 
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-container">
            <h3>화재 개별 지수 영향요인</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={breakdownData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label={({ name, value }) => `${name}: ${value.toFixed(2)}%`}
                >
                  {breakdownData.map((entry, index) => {
                    const componentKey = Object.keys(componentNames).find(key => componentNames[key] === entry.name);
                    return <Cell key={`cell-${index}`} fill={componentColors[componentKey] || '#000000'} />;
                  })}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-container">
            <h3>상위 10개 영향요인 구성요소</h3>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart 
                data={displayRbcEachData} 
                layout="vertical" 
                margin={{ left: 5, right: 30, top: 20, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  type="number" 
                  domain={xAxisDomain}
                  ticks={[0, ...Array(5).fill().map((_, i) => (i + 1) * (xAxisDomain[1] / 5))]}
                  tickFormatter={(value) => `${value.toFixed(1)}%`}
                />
                <YAxis 
                  dataKey="name" 
                  type="category" 
                  width={180}
                  tick={{ fontSize: 11 }}
                  interval={0}
                />
                <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                <Bar dataKey="value">
                  {displayRbcEachData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorPopup;