import React, { useRef, useEffect, useState } from 'react';
import './Floor.css';
import ClickableArea from './ClickableArea';

const Floor = ({ floorNumber, imageSrc, onClick, alarmTriggered, isHovered, style }) => {
  const [hovered, setHovered] = useState(false);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const imgElement = imgRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (imgElement) {
      imgElement.onload = () => {
        // Set canvas size to match the image
        canvas.width = imgElement.width;
        canvas.height = imgElement.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas first
        ctx.drawImage(imgElement, 0, 0); // Draw the image on the canvas
      };
    }
  }, [imageSrc]);

  const handleClick = (e) => {
    if (floorNumber === 0) return; // Skip base floor

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const pixel = ctx.getImageData(x, y, 1, 1).data;

    // Check if the clicked pixel is not transparent (alpha channel value)
    if (pixel[3] !== 0) {
      onClick(floorNumber); // Trigger click only if pixel is not transparent
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div 
      className={`floor-container ${isHovered ? 'hovered' : ''}`}
      style={{ ...style, position: 'absolute', top: 0, left: 0 }} 
      onClick={handleClick}
    >
      <img
        ref={imgRef}
        src={imageSrc}
        alt={`Floor ${floorNumber}`}
        className={`floor-image floor${floorNumber} ${isHovered ? 'hovered' : ''} ${alarmTriggered ? 'alarm' : ''}`}
      />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <ClickableArea
        top={0}
        left={0}
        width="100%"
        height="100%"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

export default Floor;
