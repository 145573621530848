import React from 'react';
import './ClickableArea.css';

const ClickableArea = ({ top, left, width, height, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <div
      className="clickable-area"
      style={{ top, left, width, height }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default ClickableArea;