// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: rgb(20, 30, 41);
  height: calc(100vh - 64px); /* Full height minus the app bar height */
}

.left-column, .right-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-column {
  flex: 0 0 40%;
  margin-right: 20px;
}

.right-column {
  flex: 0 0 60%;
  margin-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;EACb,iCAAiC;EACjC,0BAA0B,EAAE,yCAAyC;AACvE;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".main-page {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding: 20px;\n  background-color: rgb(20, 30, 41);\n  height: calc(100vh - 64px); /* Full height minus the app bar height */\n}\n\n.left-column, .right-column {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.left-column {\n  flex: 0 0 40%;\n  margin-right: 20px;\n}\n\n.right-column {\n  flex: 0 0 60%;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
