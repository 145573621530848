import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Legend } from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const floors = ['1호기', '2호기', '지원설비'];

const generateRandomData = () => {
  const data = [];
  for (let i = 0; i < 10; i++) {
    const entry = { name: `${i + 1}시` };
    floors.forEach(floor => {
      entry[floor] = Math.floor(Math.random() * 81) + 20; // Random values between 20 and 100
    });
    data.push(entry);
  }
  return data;
};

const controlLimits = {
  danger: 80,
  warning: 60,
  caution: 40,
  attention: 20,
  lower: 0,
};

export default function MediaCardArea({ onFloorClick }) {
  const data = React.useMemo(() => generateRandomData(), []);

  return (
    <Card sx={{ maxWidth: 900, margin: 'auto' }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          공간별 화재위험지수
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ width: 440 }}>
            <ResponsiveContainer width="90%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <ReferenceLine y={controlLimits.danger} label="심각" stroke="red" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.warning} label="경계" stroke="orange" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.caution} label="주의" stroke="yellow" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.attention} label="관심" stroke="green" strokeDasharray="3 3" />
                <ReferenceLine y={controlLimits.lower} label="안정" stroke="blue" strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                {floors.map(floor => (
                  <Line key={floor} type="monotone" dataKey={floor} stroke="#8884d8" activeDot={{ r: 8 }} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Box>
          <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TableContainer component={Paper} sx={{ width: '80%' }}>
              <Table size="small" aria-label="fire risk index by floor">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">위험지수</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {floors.map((floor, index) => (
                    <TableRow 
                      key={floor} 
                      onClick={() => onFloorClick(index + 1)}
                      sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                    >
                      <TableCell component="th" scope="row">
                        {floor}
                      </TableCell>
                      <TableCell align="right">{data[data.length - 1][floor]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
