import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

const AbnormalEventsList = () => {
  const events = [
    { id: 1, title: 'High Temperature', location: '1호기' },
    { id: 2, title: 'Smoke Detected', location: '2호기' },
    { id: 3, title: 'Low Pressure', location: '지원설비' },
  ];

  return (
    <Box sx={{ mt: 2, p: 2, backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 2 }}>
      <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Abnormal Events</Typography>
      <Box sx={{ display: 'flex', overflowX: 'auto' }}>
        {events.map((event) => (
          <Card key={event.id} sx={{ minWidth: 200, mr: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)' }}>
            <CardContent>
              <Typography variant="h6" component="div" sx={{ color: 'white' }}>
                {event.title}
              </Typography>
              <Typography sx={{ mb: 1.5, color: 'rgba(255, 255, 255, 0.7)' }}>
                {event.location}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default AbnormalEventsList;