// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.condo-container {
  position: relative;
  width: 100%;
  height: 40vh; /* Adjust this value as needed */
  margin: 0 auto;
  height: 100vh; /* Use viewport height to ensure full height */
}

.floor-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.floor-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistics {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Condo.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY,EAAE,gCAAgC;EAC9C,cAAc;EACd,aAAa,EAAE,8CAA8C;AAC/D;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".condo-container {\n  position: relative;\n  width: 100%;\n  height: 40vh; /* Adjust this value as needed */\n  margin: 0 auto;\n  height: 100vh; /* Use viewport height to ensure full height */\n}\n\n.floor-container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.floor-container img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.statistics {\n  background: white;\n  padding: 20px;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
