// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clickable-area {
    position: absolute;
    cursor: pointer;
    background-color: transparent; /* Change this line */
    z-index: 20; /* Ensure it's above the floor image */
  }`, "",{"version":3,"sources":["webpack://./src/ClickableArea.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,6BAA6B,EAAE,qBAAqB;IACpD,WAAW,EAAE,sCAAsC;EACrD","sourcesContent":[".clickable-area {\n    position: absolute;\n    cursor: pointer;\n    background-color: transparent; /* Change this line */\n    z-index: 20; /* Ensure it's above the floor image */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
