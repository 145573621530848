import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ResponsiveAppBar from './ResponsiveAppBar';
import Condo from './Condo';
import MediaCard from './MediaCard';
import MediaCardArea from './MediaCardArea';
import amber from '@mui/material/colors/amber';
import grey from '@mui/material/colors/grey';
import deepOrange from '@mui/material/colors/deepOrange';
import PaletteMode from '@mui/material/styles/createPalette';
import Box from '@mui/material/Box';
import './App.css';
import FloorPopup from './FloorPopup'; // Add this import
import AbnormalEventsList from './AbnormalEventsList';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: amber,
          divider: amber[200],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: 'rgb(21, 25, 35)',
          },
          divider: 'rgb(20, 30, 41)',
          background: {
            default: 'rgb(20, 30, 41)',
            paper: 'rgb(20, 30, 41)',
          },
          text: {
            primary: '#fff',
            secondary: grey[500],
          },
        }),
  },
});

const darkTheme = createTheme(getDesignTokens('dark'));

function MainApp() {
  const [selectedFloor, setSelectedFloor] = React.useState(null);

  const handleFloorClick = (floorNumber) => {
    setSelectedFloor(floorNumber);
  };

  return (
    <>
      <ResponsiveAppBar />
      <div className="main-page">
        <div className="left-column" style={{display: 'flex', flexDirection: 'column'}}>
          <MediaCard />
          <Box sx={{ marginBottom: '20px' }} />
          <MediaCardArea onFloorClick={handleFloorClick} />
        </div>
        <div className="right-column">
          <Condo selectedFloor={selectedFloor} onFloorClick={handleFloorClick} />
        </div>
      </div>
      {selectedFloor && (
        <FloorPopup
          floorNumber={selectedFloor}
          onClose={() => setSelectedFloor(null)}
        />
      )}
    </>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/app" element={<MainApp />} />
          <Route path="*" element={<Navigate to="/app" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
