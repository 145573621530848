// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floor-image {
  width: 100%;
  filter: grayscale(100%);
  transition: filter 0.3s, transform 0.3s;
}

.floor-image.color {
  filter: grayscale(0%);
}

.floor-image.alarm {
  filter: grayscale(0%) brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(500%);
}

.floor-image.alarm:hover {
  filter: grayscale(0%) brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(1000%);
}


.floor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30%;
  cursor: pointer;
  z-index: 10; /* Ensure the overlay is above the floor image */
}

.floor-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.floor-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.floor-image.floor1,
.floor-image.floor3 {
  filter: sepia(1) saturate(4) brightness(0.8) hue-rotate(90deg);
}

.floor-image.floor2 {
  filter: sepia(1) saturate(4) brightness(0.8) hue-rotate(0deg);
}

.floor-image.floor1.hovered,
.floor-image.floor3.hovered {
  filter: sepia(1) saturate(5) brightness(1) hue-rotate(90deg);
}

.floor-image.floor2.hovered {
  filter: sepia(1) saturate(5) brightness(1) hue-rotate(0deg);
}`, "",{"version":3,"sources":["webpack://./src/Floor.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,uBAAuB;EACvB,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mFAAmF;AACrF;;AAEA;EACE,oFAAoF;AACtF;;;AAGA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,eAAe;EACf,WAAW,EAAE,gDAAgD;AAC/D;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;;EAEE,8DAA8D;AAChE;;AAEA;EACE,6DAA6D;AAC/D;;AAEA;;EAEE,4DAA4D;AAC9D;;AAEA;EACE,2DAA2D;AAC7D","sourcesContent":[".floor-image {\n  width: 100%;\n  filter: grayscale(100%);\n  transition: filter 0.3s, transform 0.3s;\n}\n\n.floor-image.color {\n  filter: grayscale(0%);\n}\n\n.floor-image.alarm {\n  filter: grayscale(0%) brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(500%);\n}\n\n.floor-image.alarm:hover {\n  filter: grayscale(0%) brightness(50%) sepia(100%) hue-rotate(-50deg) saturate(1000%);\n}\n\n\n.floor-overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 30%;\n  cursor: pointer;\n  z-index: 10; /* Ensure the overlay is above the floor image */\n}\n\n.floor-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.floor-container img {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.floor-image.floor1,\n.floor-image.floor3 {\n  filter: sepia(1) saturate(4) brightness(0.8) hue-rotate(90deg);\n}\n\n.floor-image.floor2 {\n  filter: sepia(1) saturate(4) brightness(0.8) hue-rotate(0deg);\n}\n\n.floor-image.floor1.hovered,\n.floor-image.floor3.hovered {\n  filter: sepia(1) saturate(5) brightness(1) hue-rotate(90deg);\n}\n\n.floor-image.floor2.hovered {\n  filter: sepia(1) saturate(5) brightness(1) hue-rotate(0deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
