import React, { useState } from 'react';
import Floor from './Floor';
import ClickableArea from './ClickableArea';
import FloorPopup from './FloorPopup';
import './Condo.css';

const floors = [
  { number: 0, src: '/images/HGP_pp_all.png' },
  { number: 1, src: '/images/HGP_pp_m1.png' },
  { number: 2, src: '/images/HGP_pp_m2.png' },
  { number: 3, src: '/images/HGP_pp_boiler.png' },
];

const Condo = ({ selectedFloor, onFloorClick }) => {
  const [alarmFloors, setAlarmFloors] = useState([1, 3]);
  const [hoveredFloor, setHoveredFloor] = useState(null);

  const handleFloorHover = (floorNumber) => {
    setHoveredFloor(floorNumber);
  };

  return (
    <div className="condo-container">
      {floors.slice().reverse().map((floor, index) => (
        <Floor
          key={floor.number}
          floorNumber={floor.number}
          imageSrc={floor.src}
          onClick={() => floor.number !== 0 ? onFloorClick(floor.number) : {}}
          alarmTriggered={alarmFloors.includes(floor.number)}
          isHovered={hoveredFloor === floor.number || selectedFloor === floor.number}
          style={{ zIndex: floors.length - index }}
        />
      ))}
      <ClickableArea
        top={`${(611 / 1080) * 100}%`}
        left={`${(254 / 1920) * 100}%`}
        width={`${((531 - 254) / 1920) * 100}%`}
        height={`${((789 - 691) / 1080) * 100}%`}
        onClick={() => onFloorClick(3)}
        onMouseEnter={() => handleFloorHover(3)}
        onMouseLeave={() => handleFloorHover(null)}
      />
      <ClickableArea
        top={`${(559 / 1080) * 100}%`}
        left={`${(371 / 1920) * 100}%`}
        width={`${((507 - 371) / 1920) * 100}%`}
        height={`${((665 - 559) / 1080) * 100}%`}
        onClick={() => onFloorClick(2)}
        onMouseEnter={() => handleFloorHover(2)}
        onMouseLeave={() => handleFloorHover(null)}
      />
      <ClickableArea
        top={`${(321 / 1080) * 100}%`}
        left={`${(223 / 1920) * 100}%`}
        width={`${((382 - 223) / 1920) * 100}%`}
        height={`${((634 - 321) / 1080) * 100}%`}
        onClick={() => onFloorClick(1)}
        onMouseEnter={() => handleFloorHover(1)}
        onMouseLeave={() => handleFloorHover(null)}
      />
    </div>
  );
};

export default Condo;
